.service_block {
    margin: 0 20px;
}

.service_title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 27.5px;
    color: rgb(240, 232, 0);
    text-align: center;
    border: 2px solid white;
    border-radius: 10px;
}

.single_service_block {
    padding: 30px 0;
    border-bottom: 2px dashed white;
}

.single_service_title {
    font-weight: 550;
    font-size: 22.5px;
    color: rgb(255, 51, 51);
    margin: 0;
}

.single_service_list {
    margin: 5px 0;
    font-weight: 600;
}