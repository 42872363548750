.footer_Block {
    position:relative;
    bottom: 0px; 
    padding: 20px 0;
    background-color: #434343;
    text-align: center;
}

@media only screen and (max-width: 967px) {
    .footer_Block div p {
        font-size: 12.5px;
    }
}

@media only screen and (max-width: 680px) {
    .footer_Block div p {
        font-size: 10px;
    }
}