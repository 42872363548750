.logo_animation {
    background-color: black;
    height: 100%;
}

path {
    stroke: white;
    stroke-width: 0.1;
    fill: black;
    animation: dash 3s forwards;
}

@keyframes dash {
    to {
        fill: white;
    }
}


/* .holiday_message {
    background-color: black;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    overflow: hidden;
}

.holiday_message img {
    position: relative;
    left: 0;
    border-radius: 0;
    z-index: 0;
}

@media only screen and (max-width: 736px) {
    .holiday_message img {
        width: 100%;
        height: auto;
    }
} */