@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
body {
    margin: 0 auto;
    font-family: 'Nunito', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
    /* background-color: #06333d; */
    color: white;
}

::-webkit-scrollbar {
    width: 10px;
}


/* Track */

 ::-webkit-scrollbar-track {
    background: black;
    border-radius: 10px;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: rgb(175, 175, 175);
    border-radius: 10px;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: rgb(108, 108, 108);
}