.home_background {
    background-image: url("home_background.jpg");
    background-size: cover;
    height: 500px;
    width: 100%;
    opacity: 0.9;
}

.intro {
    padding: 50px 50px 50px 25px;
    padding-top: 140px;
    width: 45%;
    font-size: 60px;
    margin: 0;
    text-transform: uppercase;
    font-weight: 350;
}

.service_Title {
    margin: 50px 0;
    text-align: center;
    text-transform: uppercase;
}

.service_Title h2 {
    font-weight: 450;
}

.image_Block {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 50px;
}

.image_Block_Div {
    position: relative;
}

.image_Div {
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 50%;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
}

.image_Title {
    font-size: 35px;
    color: black;
    z-index: 1;
    text-align: center;
    vertical-align: center;
    cursor: default;
    position: relative;
    top: -50%;
    font-weight: 600;
}

img {
    display: inline-block;
    margin: 0 auto;
    height: 100%;
    width: auto;
    position: relative;
    left: -80px;
    border-radius: 50%;
    z-index: -1;
}

.image_Div:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out;
}

@media only screen and (max-width: 1340px) {
    .image_Div {
        width: 270px;
        height: 270px;
    }
    .image_Title {
        font-size: 30px;
    }
    img {
        left: -70px;
    }
}

@media only screen and (max-width: 1240px) {
    .image_Div {
        width: 250px;
        height: 250px;
    }
    .image_Title {
        font-size: 27.5px;
    }
    img {
        left: -60px;
    }
}

@media only screen and (max-width: 1140px) {
    .image_Div {
        width: 220px;
        height: 220px;
    }
    .image_Title {
        font-size: 25px;
    }
    img {
        left: -50px;
    }
}

@media only screen and (max-width: 1040px) {
    .image_Div {
        width: 200px;
        height: 200px;
        transition: none;
    }
    .image_Div:hover {
        transform: scaleX(1);
        transition: none;
    }
    .image_Title {
        font-size: 22.5px;
    }
    img {
        left: -40px;
    }
}

@media only screen and (max-width: 967px) {
    .intro {
        font-size: 55px;
    }
}

@media only screen and (max-width: 900px) {
    .image_Div {
        width: 170px;
        height: 170px;
    }
    .image_Title {
        font-size: 20px;
    }
    img {
        left: -35px;
    }
}

@media only screen and (max-width: 886px) {
    .intro {
        font-size: 45px;
        padding-top: 110px;
    }
    .home_background {
        height: 400px;
    }
}

@media only screen and (max-width: 775px) {
    .image_Div {
        width: 150px;
        height: 150px;
    }
    .image_Title {
        font-size: 17.5px;
    }
    img {
        left: -30px;
    }
}

@media only screen and (max-width: 733px) {
    .intro {
        font-size: 40px;
    }
}

@media only screen and (max-width: 707px) {
    .intro {
        padding-top: 95px;
    }
    .home_background {
        height: 350px;
    }
}

@media only screen and (max-width: 680px) {
    .image_Div {
        width: 130px;
        height: 130px;
    }
    .image_Title {
        font-size: 15px;
    }
    img {
        left: -25px;
    }
}

@media only screen and (max-width: 645px) {
    .intro {
        padding-top: 90px;
        font-size: 32.5px;
    }
    .home_background {
        height: 300px;
    }
    .service_Title h2 {
        font-size: 20px;
        font-weight: 350;
    }
}

@media only screen and (max-width: 590px) {
    .image_Block {
        display: flex;
        flex-wrap: wrap;
    }
    .image_Block_Div {
        padding-left: 0 5px;
    }
}

@media only screen and (max-width: 525px) {
    .intro {
        padding-top: 70px;
        font-size: 30px;
    }
    .home_background {
        height: 250px;
    }
}

@media only screen and (max-width: 484px) {
    .intro {
        padding-top: 60px;
        font-size: 25px;
    }
    .home_background {
        height: 210px;
    }
    .service_Title h2 {
        font-size: 17.5px;
        font-weight: 320;
    }
}

@media only screen and (max-width: 403px) {
    .intro {
        padding-top: 50px;
        font-size: 20px;
    }
    .home_background {
        height: 170px;
    }
    .image_Div {
        width: 120px;
        height: 120px;
    }
    .image_Title {
        font-size: 12.5px;
    }
    img {
        left: -20px;
    }
    .service_Title h2 {
        font-size: 15px;
        font-weight: 300;
    }
}