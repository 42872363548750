.contact_background {
    background-image: url("contact_background.jpg");
    background-size: cover;
    height: 340px;
    width: 100%;
}

.contact_block {
    margin: 60px 50px;
    position: relative;
    height: 150px;
}

.contact_info {
    font-weight: 600;
    text-decoration: underline;
}

.contact_mail {
    font-weight: 600;
}

.contact_add {
    font-weight: 600;
}

@media only screen and (max-width: 886px) {
    .contact_background {
        height: 300px;
    }
}

@media only screen and (max-width: 775px) {
    .contact_background {
        height: 250px;
    }
}

@media only screen and (max-width: 645px) {
    .contact_background {
        height: 220px;
    }
}

@media only screen and (max-width: 590px) {
    .contact_background {
        height: 200px;
    }
}

@media only screen and (max-width: 484px) {
    .contact_background {
        height: 160px;
    }
}

@media only screen and (max-width: 403px) {
    .contact_background {
        height: 140px;
    }
}